import React,{useState} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;
  const [isReviewsVisible, setIsReviewsVisible] = useState(false);

  const toggleReviewsVisibility = () => {
    setIsReviewsVisible(!isReviewsVisible);
  };

  return (
    <div className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin} onClick={toggleReviewsVisibility}>
      {isReviewsVisible ? <div className={css.bottomDirArrow}>&gt;</div> : <div className={css.bottomDirArrowUp}>&gt;</div>}
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {isReviewsVisible && (
        <>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={reviews} />
      </>
      )}
    </div>
  );
};

export default SectionReviews;
