import React,{useState} from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const [isContentVisible, setIsContentVisible] = useState(true);
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };
  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin} onClick={toggleContentVisibility}>
         
         {isContentVisible ? <div className={css.bottomDirArrow}>&gt;</div> : <div className={css.bottomDirArrowUp}>&gt;</div>}
         <FormattedMessage id="ListingPage.description" />
        </Heading>
      ) : null}
      {isContentVisible && <p className={textClass}>{content}</p>}
    </div>
  ) : null;
};

export default SectionTextMaybe;
